<template>
    <component
        v-if="demand"
        :is="signTemplateComponent"
        :is-loading="isIframeContentLoading" 
        :demand="demand"
        :signbook-error="signbookError"
        :signbook-url="signbookUrl"
        :signbook-token="signbookToken"
        :signbook-merchant-logo="signbookMerchantLogo"
        :nth-signbook="nthSignbook"
    ></component>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { apiRoutes, floaContractClient, redirectToReturnUrl, log } from './../helper';
import Error from './Error.vue';
    import Loader from './Loader.vue';

    export default {
        name: 'Sign',
        components: {
            Loader,
            Error,
            'sign-default': () => import('./sign/SignDefault.vue'),
            'ccl-12-24-36': () => import('./sign/SignCCL122436.vue'),
            'solvability': () => import('./sign/SignSolvability.vue'),
            'ccl-sfr': () => import('./sign/SignCCLSFR'),
            'ccl-10X': () => import('./sign/SignCCL10X.vue')
        },
        data() {
            return {
                signTemplateComponent: '',
                signbookUrl: '',
                signbookToken: '',
                signbookMerchantLogo: '',
                returnUrl: null,
                signbookError: false,
                nthSignbook: null,
                demand: null,
                isIframeContentLoading: true
            }
    },
    computed: {
            ...mapGetters([
                'getContext',
                'getReturnUrl',
                'getSignbookResponse'
            ]),
        },
        methods: {
            ...mapActions([
                'setCurrentStep',
            ] ),
            async onDisplayEventMessage(event) 
            {
                if (event.cause === "pageChanged")
                {
                    log( this.getContext, "The contract iframe content is loaded." );
                    this.isIframeContentLoading = false;
                }

                if (event.cause === "modalPosition")
                {
                    log(this.getContext, "The user clicked on send OTP code button.");
                }
            },
            onClientFileEventMessage(event)
            {
                if (this.isClientFileValid(event))
                {
                    redirectToReturnUrl(this.getReturnUrl, "true");
                } else
                {
                    if (event.state !== 'PENDING')
                    {
                        log(this.getContext, `The contract cannot be signed. NETHEOS reason : ${event.cause}. NETHEOS state : ${event.state}.`);
                        redirectToReturnUrl(this.getReturnUrl, "false")
                    }
                }
            },
            onUserEventMessage(event)
            {
                if (event.action === 'CHECK_CLAUSE') {
                    log(this.getContext, `The user ${event.context.checked ? "checked" : "unchecked"} the clause.`);
                }
                else
                {
                    log(this.getContext, `The user clicked on ${event.action} button.`);
                }

                if (event.action === 'REFUSE_SIGNATURE') {
                    redirectToReturnUrl(this.getReturnUrl, "true");
                }
            },
            onExitEventMessage()
            {
                log(this.getContext, "The user clicked on EXIT button.");
                redirectToReturnUrl(this.getReturnUrl, "true");
            },
            onErrorEventMessage(event)
            {
                log(this.getContext, `An error occured into contract iframe. Details : ${JSON.stringify(event)}`, true);
                if (event.cause === 'TOO_MANY_OTP') {
                    redirectToReturnUrl(this.getReturnUrl, "true");
                }
            },
            isClientFileValid(event)
            {
                return (
                    typeof event.changedState !== 'undefined' &&
                    (
                        typeof event.changedState.from !== 'undefined'
                        && typeof event.changedState.to !== 'undefined'
                        && event.changedState.from === 'PENDING'
                        && (event.changedState.to === 'WAITING' || event.changedState.to === 'ACCEPTED' || event.changedState.to === 'REFUSED')
                    )
                ) || (typeof event.participantState !== 'undefined' && event.participantState === 'WAITING');
            },
            loadNetheosScript() {
                let script = document.createElement('script')
                script.onload = () => {
                    this.nthSignbook = new NthSignbook({ // eslint-disable-line no-undef
                        onClientFileEventMessage: this.onClientFileEventMessage,
                        onUserEventMessage: this.onUserEventMessage,
                        onExitEventMessage: this.onExitEventMessage,
                        onErrorEventMessage: this.onErrorEventMessage,
                        onDisplayEventMessage: this.onDisplayEventMessage,
                        iframeSelectorId: 'signbook',
                        url: this.signbookUrl,
                        token: this.signbookToken
                    });
                };
                script.async = true;
                script.src = `https://${this.signbookHost}/contract/signbook/v3/script/signbook.js`;
                document.head.appendChild(script);
            },
            initTemplateFromTypeProduct(typeProduct) {
                switch (typeProduct) {
                    case 'NEW_CCL_RIB_MULTIPLE_SCALES':
                        this.signTemplateComponent = 'ccl-12-24-36';
                        break;
                    case 'NEW_CCL':
                        this.signTemplateComponent = 'ccl-10X';
                        break;
                    case 'SOLVABILITY':
                        this.signTemplateComponent = 'solvability';
                        break;
                    case 'NEW_CCL_RIB':
                        this.signTemplateComponent = 'ccl-sfr';
                        break;
                    default:
                        this.signTemplateComponent = 'sign-default';
                        break;
                }
            }
        },
    async mounted()
    {
            log(this.getContext, "Trying to get signbookResponse from store");
            let signbookResponse = this.getSignbookResponse

            if (signbookResponse == null) {
                log(this.getContext, "Signbook response from store was null, getting it from backend");
                try {
                    signbookResponse = (await floaContractClient.post(apiRoutes.signbook, { context: this.getContext })).data;
                }
                catch (error) {
                    log(this.getContext, `An error occured trying to get the signbook response. Details: ${JSON.stringify(error)}`, true);
                    redirectToReturnUrl(this.getReturnUrl, "false");
                    return;
                }
            }
            
            const signbookResponseUrl = signbookResponse.signbookUrl.split('?');
            const signbookResponseToken = signbookResponse.signbookUrl.split('?token=');
            const signbookMerchantLogo = signbookResponse.merchantLogo;
            const { hostname } = new URL(signbookResponseUrl);

            if (!signbookResponse || signbookResponseUrl.length !== 2 || signbookResponseToken.length !== 2) {
                log(this.getContext,
                    `Cannot extract data from Url context. Details : ${JSON.stringify({context: this.getContext, signbookResponse})}`,
                    true);
                return redirectToReturnUrl(this.getReturnUrl, "false");
            }

            this.signbookUrl = signbookResponseUrl[0];
            this.signbookToken = signbookResponseToken[1];
            this.returnUrl = signbookResponse.returnUrl;
            this.demand = signbookResponse.demand;
            this.signbookHost = hostname;
            this.signbookMerchantLogo = signbookMerchantLogo;

            this.initTemplateFromTypeProduct(this.demand.product.typeProduct);
            log(this.getContext, `Template contract is set to ${this.signTemplateComponent}.`);
            this.loadNetheosScript();
        
            log(this.getContext, "Contract page is mounted.");
        },
    }
</script>
